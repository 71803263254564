import { defineStore } from 'pinia';
import { stringify } from 'qs';
import { useApiFetch } from '~/composables/useApiFetch';
import { type LinkModel, type SimpleStatusModel } from '~/models/arbitrary';
import { type Instrument } from '~/models/instruments';
import {
  type Dashboard,
  type GroupInvestment,
  type PagedTradeOrders,
  type PortfolioListViewModel,
  type PortfolioSummary,
  type PositionInstrument,
} from '~/models/portfolios';
import {
  MoneyWithdrawalReasonType,
  OrderState,
  TradeDirection,
  TransactionContext,
  type HistoryTransaction,
  type MoneyOrder,
  type PagedPurchaseInstructions,
  type PagedTransactionHistory,
  type TradeOrder,
} from '~/models/transactions';
import { useAuthStore } from './auth';
import { useInstrumentsStore } from './instruments';
import { useSurveysStore } from './surveys';

type GroupedTradeOrders = { [key: string]: { Amount: number; Instrument: Instrument }[] };

export const usePortfoliosStore = defineStore('portfolios', {
  state: () => ({
    loadingPortfolioSummary: true,
    portfolioSummary: null as PortfolioSummary | null,
    loadingPositionInstruments: true,
    positionInstruments: [] as PositionInstrument[],
    loadingGroupInvestments: true,
    groupInvestments: [] as GroupInvestment[],
    loadingTradeOrders: true,
    tradeOrders: [] as TradeOrder[],
    tradeOrdersAsPositionInstruments: [] as PositionInstrument[],
    loadingPortfolios: true,
    portfolios: [] as PortfolioListViewModel[],
    portfoliosForProductIds: {} as { [key: string]: PortfolioListViewModel[] },
  }),
  getters: {
    hasInvestments: (state) => {
      return state.groupInvestments.some(
        (groupInvestment) => groupInvestment.PurchasePriceTotal > 0,
      );
    },
    wallet: (state) => {
      return state.portfolios.find((portfolio) => portfolio.IsWallet)!;
    },
  },
  actions: {
    async getDashboard({ force }: { force?: boolean } = { force: false }) {
      // TODO(martin_obadal): add force param & what will happen in case user is
      // newly registered and doesn't have items in these arrays?
      if (
        !force &&
        this.portfolioSummary &&
        this.positionInstruments.length > 0 &&
        this.groupInvestments.length > 0 &&
        this.tradeOrders.length > 0
      ) {
        return;
      }

      this.loadingPortfolioSummary = true;
      this.loadingGroupInvestments = true;

      const instrumentsStore = useInstrumentsStore();
      const authStore = useAuthStore();
      const surveysStore = useSurveysStore();
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';
      const fetch = useApiFetch();

      await Promise.all([
        instrumentsStore.getInstruments({}),
        instrumentsStore.getInstrumentGroups({}),
        surveysStore.getClientSurveys({}),
      ]);

      const [dashboard] = await Promise.all([
        fetch<Dashboard>(
          `/product/dashboard?clientId=${authStore.user.Id}&actualBalanceIncludeActiveTradeOrders=true`,
        ),
        this.getTradeOrders({ page: 1, pageSize: 40_000 }),
        this.getPositionInstruments(),
      ]);

      this.portfolioSummary =
        dashboard.PortfolioSummary.find(
          (portfolioSummary) => portfolioSummary.Currency === 'CZK',
        ) ?? null;

      this.loadingPortfolioSummary = false;

      const investmentsInGroups = instrumentsStore.instrumentGroupsCache[language].data.map(
        (instrumentGroup) =>
          ({
            Id: instrumentGroup.Id,
            Code: instrumentGroup.Code,
            Name: instrumentGroup.Name,
            PurchasePriceTotal: 0,
            Percentage: 0,
            Profit: 0,
            PositionInstruments: [],
          }) as GroupInvestment,
      );

      const groupInvestments = [
        ...this.tradeOrdersAsPositionInstruments,
        ...this.positionInstruments,
      ]
        .map((positionInstrument) => ({
          InstrumentGroups: positionInstrument.Instrument.InstrumentGroups,
          PurchasePriceTotal: positionInstrument.TradeOrdersSum
            ? positionInstrument.TradeOrdersSum
            : positionInstrument.PurchasePriceTotal,
          Profit: positionInstrument.MarketPriceTotal
            ? positionInstrument.MarketPriceTotal - positionInstrument.PurchasePriceTotal
            : 0,
          Instrument: positionInstrument.Instrument,
          PositionInstrument: positionInstrument,
        }))
        .filter((positionInstrument) => positionInstrument.InstrumentGroups.length === 1)
        .reduce((accumulated, current) => {
          const group = accumulated.find((group) => group.Id === current.InstrumentGroups[0].Id);

          if (group) {
            group.PurchasePriceTotal += current.PurchasePriceTotal;
            group.Profit += current.Profit;
            group.PositionInstruments.push({
              ...current.PositionInstrument,
              Instrument: { ...current.Instrument },
            });
          }

          return accumulated;
        }, investmentsInGroups);
      const total = groupInvestments.reduce(
        (accumulated, current) => (accumulated += current.PurchasePriceTotal),
        0,
      );

      groupInvestments.forEach((groupInvestment) => {
        groupInvestment.Percentage = (groupInvestment.PurchasePriceTotal / total) * 100;
      });

      this.groupInvestments = groupInvestments;
      this.loadingGroupInvestments = false;
    },
    async getPositionInstruments() {
      const fetch = useApiFetch();
      const instrumentsStore = useInstrumentsStore();
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      this.loadingPositionInstruments = true;

      const positionInstruments = await fetch<PositionInstrument[]>('/product/positioninstruments');

      if (!instrumentsStore.instrumentsCache[language].loaded) {
        await instrumentsStore.getInstruments({});
      }

      positionInstruments.forEach((positionInstrument) => {
        positionInstrument.Instrument = instrumentsStore.instrumentsCache[language].data.find(
          (instrument) => instrument.Id === positionInstrument.Instrument.Id,
        )!;
      });

      // TODO(martin_obadal): is sort missing here?
      // filter non gallery position instruments
      this.positionInstruments = positionInstruments.filter(
        (positionInstrument) => !!positionInstrument.Instrument,
      );
      this.loadingPositionInstruments = false;
    },
    async getTradeOrders({
      page,
      pageSize,
      instrumentId,
      from,
      to,
    }: {
      page: number;
      pageSize: number;
      instrumentId?: string;
      from?: Date;
      to?: Date;
    }) {
      const authStore = useAuthStore();
      const instrumentsStore = useInstrumentsStore();
      const fetch = useApiFetch();
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      this.loadingTradeOrders = true;

      const response = await fetch<PagedTradeOrders>(
        `/portfolio/${authStore.user.Id}/tradeorder${stringify(
          {
            instrumentId: instrumentId || 'undefined',
            from,
            to,
            pageSize,
            page,
          },
          { skipNulls: true, addQueryPrefix: true },
        )}`,
      );

      if (!instrumentsStore.instrumentsCache[language].loaded) {
        await instrumentsStore.getInstruments({});
      }

      response.TradeOrders.forEach((tradeOrder) => {
        tradeOrder.Instrument = instrumentsStore.instrumentsCache[language].data.find(
          (instrument) => instrument.Id === tradeOrder.InstrumentId,
        )!;
      });

      this.tradeOrders = response.TradeOrders;

      const groupedTradeOrders = this.tradeOrders
        .filter((tradeOrder) => tradeOrder.OrderState === OrderState.Active)
        .reduce((accumulated, current) => {
          if (!accumulated[current.Instrument.Id]) {
            accumulated[current.Instrument.Id] = [];
          }

          accumulated[current.Instrument.Id].push({
            Amount: current.Amount ?? 0,
            Instrument: current.Instrument,
          });

          return accumulated;
        }, {} as GroupedTradeOrders);

      this.tradeOrdersAsPositionInstruments = Object.keys(groupedTradeOrders).map(
        (key) =>
          ({
            Id: key,
            Instrument: groupedTradeOrders[key][0].Instrument,
            TradeOrdersSum: groupedTradeOrders[key].reduce(
              (accumulated, current) => accumulated + (current.Amount ?? 0),
              0,
            ),
            MarketPriceTotal: 0,
            PurchasePriceTotal: 0,
          }) as PositionInstrument,
      );
      this.loadingTradeOrders = false;

      return { TradeOrders: response.TradeOrders, TotalCount: response.TotalCount };
    },
    async getPortfolios({
      productIds = [],
      force = false,
    }: {
      productIds?: string[];
      force?: boolean;
    }) {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      if (this.portfolios.length > 0 && !force) {
        return;
      }

      this.loadingPortfolios = true;

      const response = await fetch<PortfolioListViewModel[]>(
        `/product/portfolios/${authStore.user.Id}${stringify(
          { productIds },
          { addQueryPrefix: true, indices: true },
        )}`,
      );

      if (productIds.length === 0) {
        this.portfolios = response;
      } else {
        productIds.forEach((productId) => {
          this.portfoliosForProductIds[productId] = response.filter(
            (portfolio) => portfolio.ProductId?.toLowerCase() === productId.toLowerCase(),
          );
        });
      }

      this.loadingPortfolios = false;

      return response;
    },
    async getTransactionsHistory({
      page,
      pageSize,
      from,
      to,
      instrumentIds,
      transactionContexts,
    }: {
      page: number;
      pageSize: number;
      from: string;
      to: string;
      instrumentIds: string[] | null;
      transactionContexts: TransactionContext[];
    }) {
      const authStore = useAuthStore();
      const fetch = useApiFetch();
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      let portfolioIds = [] as string[];

      if (!instrumentIds) {
        portfolioIds = this.portfolios.map((portfolio) => portfolio.Id);
      } else if (this.portfolios.find((portfolio) => portfolio.IsWallet)?.Id === instrumentIds[0]) {
        portfolioIds = [this.portfolios.find((portfolio) => portfolio.IsWallet)!.Id];
        instrumentIds = null;
      } else {
        portfolioIds = this.portfolios
          .filter((portfolio) => !portfolio.IsWallet)
          .map((portfolio) => portfolio.Id);
      }

      const response = await fetch<PagedTransactionHistory>('/dashboard/transactions-activity', {
        method: 'POST',
        body: {
          page,
          pageSize,
          from,
          to,
          instrumentIds,
          portfolioIds,
          transactionContexts,
          clientId: authStore.user.Id,
          locale: language,
          currency: 'CZK',
          sortBy: 'CreatedOn',
          sortHow: 'Desc',
        },
      });

      return [response.Activities, response.TotalCount] as [HistoryTransaction[], number];
    },
    async getTransactionsHistoryDownloadUrl({
      from,
      to,
      instrumentIds,
      transactionContexts,
      format,
    }: {
      from: string;
      to: string;
      instrumentIds: string[] | null;
      transactionContexts: TransactionContext[];
      format: 'pdf' | 'csv';
    }) {
      const authStore = useAuthStore();
      const fetch = useApiFetch();
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      let portfolioIds = [] as string[];

      if (!instrumentIds) {
        portfolioIds = this.portfolios.map((portfolio) => portfolio.Id);
      } else if (this.portfolios.find((portfolio) => portfolio.IsWallet)?.Id === instrumentIds[0]) {
        portfolioIds = [this.portfolios.find((portfolio) => portfolio.IsWallet)!.Id];
        instrumentIds = null;
      } else {
        portfolioIds = this.portfolios
          .filter((portfolio) => !portfolio.IsWallet)
          .map((portfolio) => portfolio.Id);
      }

      return await fetch<LinkModel>(
        `/dashboard/${authStore.user.Id}/transactions-activity/link${stringify(
          {
            from,
            to,
            format,
            instrumentIds,
            portfolioIds,
            transactionContexts,
            clientId: authStore.user.Id,
            locale: language,
            currency: 'CZK',
            sortBy: 'CreatedOn',
            sortHow: 'Desc',
          },
          { addQueryPrefix: true, encode: false, skipNulls: true },
        )}`,
      );
    },
    async createEmptyPortfolio() {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      await fetch<SimpleStatusModel>(`/product/${authStore.user.Id}/emptyportfolio`, {
        method: 'POST',
      });
    },
    async createTradeOrder({ instrumentId, amount }: { instrumentId: string; amount: number }) {
      const authStore = useAuthStore();
      const portfoliosStore = usePortfoliosStore();
      const instrumentsStore = useInstrumentsStore();
      const fetch = useApiFetch();

      const portfolio = portfoliosStore.portfolios.find(
        (portfolio) => !portfolio.IsWallet && portfolio.Currency === 'CZK',
      );

      if (!portfolio) {
        return { Status: false };
      }

      const instrument = instrumentsStore.instruments.find(
        (instrument) => instrument.Id === instrumentId,
      )!;

      return await fetch<SimpleStatusModel>(
        `/product/${authStore.user.Id}/${portfolio.Id}/tradeorder${stringify(
          {
            instrumentId,
            currency: instrument.Currency,
            amount,
            orderDirection: TradeDirection.Buy,
          },
          { addQueryPrefix: true },
        )}`,
        { method: 'POST' },
      );
    },
    async transferMoney({
      targetPortfolioId,
      amount,
    }: {
      targetPortfolioId: string;
      amount: number;
    }) {
      const fetch = useApiFetch();

      return await fetch<MoneyOrder>('/portfolio/moneytransfer', {
        method: 'POST',
        body: {
          portfolioSourceId: this.wallet?.Id,
          portfolioTargetId: targetPortfolioId,
          currency: this.wallet?.Currency,
          liquidate: false,
          amount,
        },
      });
    },
    async withdraw({
      verificationCode,
      amount,
      bankAccountId,
      reason,
      comment,
    }: {
      verificationCode: string;
      amount: number;
      bankAccountId: string;
      reason: typeof NaN | MoneyWithdrawalReasonType;
      comment: string;
    }) {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      try {
        return await fetch<MoneyOrder>(`/portfolio/withdraw`, {
          method: 'POST',
          body: {
            targetClientId: authStore.user.Id,
            id: this.wallet?.Id,
            code: verificationCode,
            bankAccountId,
            amount,
            currency: this.wallet?.Currency,
            moneyWithdrawalReasonType: reason || undefined,
            moneyWithdrawalReason: comment || undefined,
          },
        });
      } catch (error) {
        return null;
      }
    },
    async getPurchaseInstructions({
      page,
      pageSize,
      from,
      to,
    }: {
      page: number;
      pageSize: number;
      from?: Date;
      to?: Date;
    }) {
      const authStore = useAuthStore();
      const fetch = useApiFetch();
      const instrumentsStore = useInstrumentsStore();
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      const response = await fetch<PagedPurchaseInstructions>(
        `/product/${authStore.user.Id}/gallery/orders${stringify(
          {
            from,
            to,
            pageSize,
            page,
          },
          { skipNulls: true, addQueryPrefix: true },
        )}`,
      );

      if (!instrumentsStore.instrumentsCache[language].loaded) {
        await instrumentsStore.getInstruments({});
      }

      response.Orders.forEach((tradeOrder) => {
        tradeOrder.Instrument = instrumentsStore.instrumentsCache[language].data.find(
          (instrument) => instrument.Id === tradeOrder.Instrument.Id,
        )!;
      });

      return { PurchaseInstructions: response.Orders, TotalCount: response.TotalCount };
    },
    _resetCache() {
      this.loadingPortfolioSummary = true;
      this.portfolioSummary = null;
      this.loadingPositionInstruments = true;
      this.positionInstruments = [];
      this.loadingGroupInvestments = true;
      this.groupInvestments = [];
      this.loadingTradeOrders = true;
      this.tradeOrders = [];
      this.tradeOrdersAsPositionInstruments = [];
      this.loadingPortfolios = true;
      this.portfolios = [];
      this.portfoliosForProductIds = {};
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePortfoliosStore, import.meta.hot));
}
