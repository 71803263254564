import { defineStore } from 'pinia';
import { stringify } from 'qs';
import { useAuthStore } from './auth';
import { useApiFetch } from '~/composables/useApiFetch';
import {
  OperationType,
  type ImpersonationToken,
  type PasswordResetMessage,
  type SignInToken,
  type Token,
} from '~/models/arbitrary';

type GetTokenPayload = { email: string; password: string; useSecondaryProvider: boolean };
type TwoFactorSingInPayload = { email: string; smsCode: string };

export const useSecurityStore = defineStore('security', {
  state: () => ({
    loginSmsRequestTime: undefined as number | undefined,
    firstCodeResetTime: undefined as number | undefined,
    secondCodeRequestTime: undefined as number | undefined,
    hasRequestedCode: false,
  }),
  actions: {
    async getToken({ email, password, useSecondaryProvider }: GetTokenPayload) {
      const fetch = useApiFetch();

      if (
        useSecondaryProvider &&
        this.loginSmsRequestTime &&
        this.loginSmsRequestTime >= Date.now() - 1000 * 60 * 3
      ) {
        throw { data: { error: 'too_many_code_attempts' } };
      }

      if (useSecondaryProvider) {
        this.loginSmsRequestTime = Date.now();
      }
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const response = await fetch<{
        access_token: string;
        expires_in: number;
        refresh_token: string;
        token_type: string;
      }>('/token', {
        method: 'POST',
        body: {
          // eslint-disable-next-line camelcase
          grant_type: 'password',
          username: email,
          password,
          useSecondaryProvider,
          locale: locale.value,
        },
        headers: {
          Accept: 'application/x-www-form-urlencoded',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      return {
        AccessToken: response.access_token,
        ExpiresIn: response.expires_in,
        ExpiresAt: 0,
        RefreshToken: response.refresh_token,
        TokenType: response.token_type,
      } as Token;
    },
    async refreshToken({ refreshToken }: { refreshToken: string }) {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      const response = await fetch<{
        access_token: string;
        expires_in: number;
        refresh_token: string;
        token_type: string;
      }>('/token', {
        method: 'POST',
        body: {
          /* eslint-disable camelcase */
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
          client_id: authStore.user.Id,
          /* eslint-enable camelcase */
        },
        headers: {
          Accept: 'application/x-www-form-urlencoded',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      return {
        AccessToken: response.access_token,
        ExpiresIn: response.expires_in,
        ExpiresAt: 0,
        RefreshToken: response.refresh_token,
        TokenType: response.token_type,
      } as Token;
    },
    async twoFactorSingIn({ email, smsCode }: TwoFactorSingInPayload) {
      const fetch = useApiFetch();

      const response = await fetch<SignInToken>(`/security/${email}/twofactorsignin/${smsCode}`, {
        method: 'POST',
      });

      return {
        AccessToken: response.AccessToken,
        ExpiresIn: response.ExpiresIn,
        RefreshToken: response.RefreshToken,
        TokenType: response.TokenType,
        ExpiresAt: 0,
      } as Token;
    },
    async logout() {
      const fetch = useApiFetch();

      return await fetch('/security/logout', { method: 'POST' });
    },
    async logoutAllDevices() {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      return await fetch(`/security/${authStore.user.Id}/logout/all`, { method: 'POST' });
    },
    requestTwoFactorCode({ operation }: { operation: OperationType }) {
      let hasRequestedRef = this.hasRequestedCode;

      if (this.secondCodeRequestTime) {
        if (this.firstCodeResetTime) {
          this.firstCodeResetTime = undefined;
        }

        if (this.secondCodeRequestTime >= Date.now() - 1000 * 60 * 3) {
          return Promise.resolve(undefined);
        } else {
          this.hasRequestedCode = false;
          hasRequestedRef = false;
          this.secondCodeRequestTime = undefined;
        }
      }

      const canRequestCode = !hasRequestedRef || (hasRequestedRef && !this.secondCodeRequestTime);

      if (!hasRequestedRef) {
        this.hasRequestedCode = true;
        this.firstCodeResetTime = Date.now();
      } else {
        this.secondCodeRequestTime = Date.now();
      }

      if (canRequestCode) {
        try {
          return this.getTwoFactorCode({
            useSecondaryProvider: !!this.secondCodeRequestTime,
            operation,
          });
        } catch (error) {
          return Promise.resolve(undefined);
        }
      } else {
        return Promise.resolve(undefined);
      }
    },
    resetTwoFactorCodeRequests() {
      this.hasRequestedCode = false;
      this.firstCodeResetTime = undefined;
      this.secondCodeRequestTime = undefined;
    },
    getTwoFactorCode({
      useSecondaryProvider,
      operation,
    }: {
      useSecondaryProvider: boolean;
      operation: OperationType;
    }) {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      return fetch<{ Id: string }>(
        `/security/${authStore.user.Id}/two-factor/code${stringify(
          { useSecondaryProvider, operationType: operation },
          { addQueryPrefix: true },
        )}`,
      );
    },
    async requestPasswordChange({ useSecondaryProvider }: { useSecondaryProvider: boolean }) {
      const fetch = useApiFetch();

      return await fetch<PasswordResetMessage>(
        `/security/password/requestchange/${stringify(
          { useSecondaryProvider },
          { addQueryPrefix: true },
        )}`,
        { method: 'POST' },
      );
    },
    async resetPassword({
      password,
      smsCode,
      email,
      identityNumberToken,
    }: {
      smsCode: string;
      password: string;
      email: string;
      identityNumberToken: string;
    }) {
      const fetch = useApiFetch();

      await fetch('/security/password/change', {
        method: 'POST',
        body: {
          password,
          email,
          code: smsCode,
          phoneReset: true,
          identityNumberToken,
        },
      });
    },
    async emailSignIn({
      email,
      code,
      isMobileUpload,
      id,
    }: {
      email: string;
      code: string;
      isMobileUpload: boolean;
      id: string | undefined;
    }) {
      const fetch = useApiFetch();

      const response = await fetch<SignInToken>(
        `/security/${email || 'null'}/signin/${code}${stringify(
          { isMobileUpload, id },
          { addQueryPrefix: true },
        )}`,
        { method: 'POST' },
      );

      return {
        AccessToken: response.AccessToken,
        ExpiresIn: response.ExpiresIn,
        RefreshToken: response.RefreshToken,
        TokenType: response.TokenType,
        ExpiresAt: 0,
      } as Token;
    },
    async impersonationSignIn({ code }: { code: string }) {
      const fetch = useApiFetch();

      const response = await fetch<ImpersonationToken>(`/security/impersonation/${code}`, {
        method: 'POST',
      });

      return {
        ...response,
        ExpiresAt: 0,
      };
    },
    getPhoneVerificationSmsCode() {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      let hasRequestedRef = this.hasRequestedCode;

      if (this.secondCodeRequestTime) {
        if (this.firstCodeResetTime) {
          this.firstCodeResetTime = undefined;
        }

        if (this.secondCodeRequestTime >= Date.now() - 1000 * 60 * 3) {
          return Promise.resolve(undefined);
        } else {
          this.hasRequestedCode = false;
          hasRequestedRef = false;
          this.secondCodeRequestTime = undefined;
        }
      }

      const canRequestCode = !hasRequestedRef || (hasRequestedRef && !this.secondCodeRequestTime);

      if (!hasRequestedRef) {
        this.hasRequestedCode = true;
        this.firstCodeResetTime = Date.now();
      } else {
        this.secondCodeRequestTime = Date.now();
      }

      if (canRequestCode) {
        try {
          return fetch<{ Id: string }>(
            `/security/${authStore.user.Id}/two-factor/code${stringify(
              {
                useSecondaryProvider: !!this.secondCodeRequestTime,
                operationType: OperationType.VerifyPhone,
              },
              { addQueryPrefix: true },
            )}`,
          );
        } catch (error) {
          return undefined;
        }
      } else {
        return undefined;
      }
    },
    getVerificationSmsCode() {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      let hasRequestedRef = this.hasRequestedCode;

      if (this.secondCodeRequestTime) {
        if (this.firstCodeResetTime) {
          this.firstCodeResetTime = undefined;
        }

        if (this.secondCodeRequestTime >= Date.now() - 1000 * 60 * 3) {
          return Promise.resolve(undefined);
        } else {
          this.hasRequestedCode = false;
          hasRequestedRef = false;
          this.secondCodeRequestTime = undefined;
        }
      }

      const canRequestCode = !hasRequestedRef || (hasRequestedRef && !this.secondCodeRequestTime);

      if (!hasRequestedRef) {
        this.hasRequestedCode = true;
        this.firstCodeResetTime = Date.now();
      } else {
        this.secondCodeRequestTime = Date.now();
      }

      if (canRequestCode) {
        try {
          return fetch<{ Id: string }>(
            `/security/${authStore.user.Id}/two-factor/code${stringify(
              {
                useSecondaryProvider: !!this.secondCodeRequestTime,
                operationType: OperationType.AgreementSigning,
              },
              { addQueryPrefix: true },
            )}`,
          );
        } catch (error) {
          return undefined;
        }
      } else {
        return undefined;
      }
    },
    async verifyPhone({ verificationCode }: { verificationCode: string }) {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      return await fetch<{ Code: string }>(`/verification/${authStore.user.Id}/sms`, {
        method: 'POST',
        body: {
          code: verificationCode,
        },
      });
    },
    async verifyEmail({ verificationCode, email }: { verificationCode: string; email: string }) {
      const fetch = useApiFetch();

      return await fetch<{ Code: string }>(`/verification/${email}/email/${verificationCode}`, {
        method: 'POST',
      });
    },
    async forgottenPassword({ email }: { email: string }) {
      const fetch = useApiFetch();

      let hasRequestedRef = this.hasRequestedCode;

      if (this.secondCodeRequestTime) {
        if (this.firstCodeResetTime) {
          this.firstCodeResetTime = undefined;
        }

        if (this.secondCodeRequestTime >= Date.now() - 1000 * 60 * 3) {
          return Promise.resolve(undefined);
        } else {
          this.hasRequestedCode = false;
          hasRequestedRef = false;
          this.secondCodeRequestTime = undefined;
        }
      }

      const canRequestCode = !hasRequestedRef || (hasRequestedRef && !this.secondCodeRequestTime);

      if (!hasRequestedRef) {
        this.hasRequestedCode = true;
        this.firstCodeResetTime = Date.now();
      } else {
        this.secondCodeRequestTime = Date.now();
      }

      if (canRequestCode) {
        try {
          return await fetch<PasswordResetMessage>(`/security/password/reset/${email}`, {
            method: 'POST',
            body: {
              useSecondaryProvider: !!this.secondCodeRequestTime,
            },
          });
        } catch (error) {
          return undefined;
        }
      } else {
        return undefined;
      }
    },
    async confirmForgottenPassword({
      email,
      password,
      verificationCode,
      identityNumberToken,
    }: {
      email: string;
      password: string;
      verificationCode: string;
      identityNumberToken: string;
    }) {
      const fetch = useApiFetch();

      return await fetch<{ Id: string }>('/security/password/change', {
        body: {
          email,
          code: verificationCode,
          password,
          phoneReset: true,
          identityNumberToken,
        },
        method: 'POST',
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSecurityStore, import.meta.hot));
}
