/* eslint-disable import/namespace */
import * as dateFns from 'date-fns';

export function useDateFns() {
  const nuxtApp = useNuxtApp();
  const dateObj = { ...dateFns };

  function parsedDate(date: any) {
    if (dateFns.parseISO) {
      return typeof date !== 'string' ? date : dateFns.parseISO(date);
    }

    return date;
  }

  if (dateFns.intlFormat) {
    dateObj.intlFormat = (
      argument: Date | number,
      formatOptions?: {
        localeMatcher?: 'lookup' | 'best fit';
        weekday?: 'narrow' | 'short' | 'long';
        era?: 'narrow' | 'short' | 'long';
        year?: 'numeric' | '2-digit';
        month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long';
        day?: 'numeric' | '2-digit';
        hour?: 'numeric' | '2-digit';
        minute?: 'numeric' | '2-digit';
        second?: 'numeric' | '2-digit';
        timeZoneName?: 'short' | 'long';
        formatMatcher?: 'basic' | 'best fit';
        hour12?: boolean;
        timeZone?: string;
      },
    ) => {
      return dateFns.intlFormat(argument, formatOptions, {
        locale: nuxtApp.$i18n.locale.value as 'cs' | 'en',
      });
    };
  }

  if (dateFns.format) {
    dateObj.format = (date: any, format: string, options: any) => {
      return dateFns.format(parsedDate(date), format, mergeOptions(options));
    };
  }

  if (dateFns.parse) {
    dateObj.parse = (
      dateString: string,
      formatString: string,
      backupDate: number | Date,
      options: any,
    ) => {
      return dateFns.parse(dateString, formatString, backupDate, mergeOptions(options));
    };
  }

  const threeParams = (
    [
      'differenceInCalendarWeeks',
      'formatDistance',
      'formatDistanceStrict',
      'formatRelative',
      'isMatch',
      'isSameWeek',
      'setDay',
      'setWeek',
      'setWeekYear',
    ] as const
  ).filter((value) => Object.keys(dateFns).includes(value));

  for (const fn of threeParams) {
    // @ts-ignore
    dateObj[fn] = (param1: any, param2: any, options: any) => {
      // @ts-ignore
      return dateFns[fn](param1, param2, mergeOptions(options));
    };
  }

  const twoParams = (
    [
      'eachWeekOfInterval',
      'endOfWeek',
      'formatDistanceToNow',
      'formatDistanceToNowStrict',
      'formatDuration',
      'getWeek',
      'getWeekOfMonth',
      'getWeeksInMonth',
      'getWeekYear',
      'isThisWeek',
      'lastDayOfWeek',
      'startOfWeek',
      'startOfWeekYear',
    ] as const
  ).filter((value) => Object.keys(dateFns).includes(value));

  for (const fn of twoParams) {
    // @ts-ignore
    dateObj[fn] = (param: any, options: any) => {
      return dateFns[fn](param, mergeOptions(options));
    };
  }

  function mergeOptions(options: any) {
    options = { ...options };

    options.locale = nuxtApp.$dateFnsLocales[nuxtApp.$i18n.locale.value as 'cs' | 'en'];

    return options;
  }

  return {
    ...dateObj,
  };
}
