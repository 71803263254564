import { default as _91_46_46_46not_45found_938JFFRGL5a6Meta } from "C:/FE02/_work/213/s/Gallery/pages/[...not-found].vue?macro=true";
import { default as aboutuXJ28kYUHrMeta } from "C:/FE02/_work/213/s/Gallery/pages/about.vue?macro=true";
import { default as account_45created89byGPMxieMeta } from "C:/FE02/_work/213/s/Gallery/pages/account/account-created.vue?macro=true";
import { default as additional_45informationwshpded0ATMeta } from "C:/FE02/_work/213/s/Gallery/pages/account/additional-information.vue?macro=true";
import { default as addresses4UR44RrFuCMeta } from "C:/FE02/_work/213/s/Gallery/pages/account/addresses.vue?macro=true";
import { default as amendmentgsL3mWUY30Meta } from "C:/FE02/_work/213/s/Gallery/pages/account/amendment.vue?macro=true";
import { default as indexju3Via4vkCMeta } from "C:/FE02/_work/213/s/Gallery/pages/account/bank-account/index.vue?macro=true";
import { default as requestedQyiHbQ05BmMeta } from "C:/FE02/_work/213/s/Gallery/pages/account/bank-account/requested.vue?macro=true";
import { default as contract_45signingGK8eCMMMizMeta } from "C:/FE02/_work/213/s/Gallery/pages/account/contract-signing.vue?macro=true";
import { default as document_45update5U0vGNpM7MMeta } from "C:/FE02/_work/213/s/Gallery/pages/account/document-update.vue?macro=true";
import { default as documents_45checkw1YBocu0flMeta } from "C:/FE02/_work/213/s/Gallery/pages/account/documents-check.vue?macro=true";
import { default as indexfnfjgs0N3UMeta } from "C:/FE02/_work/213/s/Gallery/pages/account/identity-verification/index.vue?macro=true";
import { default as requestedawGuRAaZGrMeta } from "C:/FE02/_work/213/s/Gallery/pages/account/identity-verification/requested.vue?macro=true";
import { default as upload_45documents3KKtg4nRXkMeta } from "C:/FE02/_work/213/s/Gallery/pages/account/identity-verification/upload-documents.vue?macro=true";
import { default as personal_45datasXInFy6uXxMeta } from "C:/FE02/_work/213/s/Gallery/pages/account/personal-data.vue?macro=true";
import { default as phone_45number_45verificationOlnltbJDFOMeta } from "C:/FE02/_work/213/s/Gallery/pages/account/phone-number-verification.vue?macro=true";
import { default as index8T0gCK7A3oMeta } from "C:/FE02/_work/213/s/Gallery/pages/account/politically-exposed-person/index.vue?macro=true";
import { default as requestedsJES8p2x1HMeta } from "C:/FE02/_work/213/s/Gallery/pages/account/politically-exposed-person/requested.vue?macro=true";
import { default as questionnaireTIUkWP34K1Meta } from "C:/FE02/_work/213/s/Gallery/pages/account/questionnaire.vue?macro=true";
import { default as indexfrpQSq57n9Meta } from "C:/FE02/_work/213/s/Gallery/pages/account/source-of-income-statement/index.vue?macro=true";
import { default as requestedRZV0NSQN8zMeta } from "C:/FE02/_work/213/s/Gallery/pages/account/source-of-income-statement/requested.vue?macro=true";
import { default as verificationzUDhQqBJhVMeta } from "C:/FE02/_work/213/s/Gallery/pages/account/verification.vue?macro=true";
import { default as annual_45reports5mAdj92ygJMeta } from "C:/FE02/_work/213/s/Gallery/pages/annual-reports.vue?macro=true";
import { default as bankidl5YX5Dlu0TMeta } from "C:/FE02/_work/213/s/Gallery/pages/bankid.vue?macro=true";
import { default as contactMpEFCkihgUMeta } from "C:/FE02/_work/213/s/Gallery/pages/contact.vue?macro=true";
import { default as contractual_45documentationQa9EB7QJyXMeta } from "C:/FE02/_work/213/s/Gallery/pages/contractual-documentation.vue?macro=true";
import { default as _91id_93yOgJekiScZMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/[id].vue?macro=true";
import { default as _91id_93PyTYowHiRbMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/advertisement/[id].vue?macro=true";
import { default as newldlaJ2AlsiMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/advertisement/new.vue?macro=true";
import { default as indexBbl9ITyHtvMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/index.vue?macro=true";
import { default as my_45advertisementsmwddt4FSxpMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/my-advertisements.vue?macro=true";
import { default as termsPxkUxQOYXrMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/terms.vue?macro=true";
import { default as _91id_93NVgftnlcMhMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/trades/[id].vue?macro=true";
import { default as indexQGd5psaGXTMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/trades/index.vue?macro=true";
import { default as newxDrrkmeOrbMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/trades/new.vue?macro=true";
import { default as advertising_45spacemVjTtWOJN0Meta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space.vue?macro=true";
import { default as depositubNLWpXU31Meta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/deposit.vue?macro=true";
import { default as indexXEk0Kb2YL2Meta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/index.vue?macro=true";
import { default as purchase_45instructions89hNf5NpeqMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/purchase-instructions.vue?macro=true";
import { default as transactions_45historyZfoFl45115Meta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/transactions-history.vue?macro=true";
import { default as confirmationfAJP39ZYhIMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/withdrawal/confirmation.vue?macro=true";
import { default as indexqnDQl4F67pMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/withdrawal/index.vue?macro=true";
import { default as financeE4CnVlxqpSMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/finance.vue?macro=true";
import { default as indexuB0mVHctNbMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/gifts-and-benefits/index.vue?macro=true";
import { default as unique_45exhibition_45alfons_45muchaHZr1AKrVCRMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/gifts-and-benefits/unique-exhibition-alfons-mucha.vue?macro=true";
import { default as vouchersgmiivzAEfUMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/gifts-and-benefits/vouchers.vue?macro=true";
import { default as gifts_45and_45benefitsfgA9QgwpFcMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/gifts-and-benefits.vue?macro=true";
import { default as indexyeY3BsVCFMMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/index.vue?macro=true";
import { default as index1vDmhh3GFvMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/index.vue?macro=true";
import { default as _91slug_93GZ0FWtAS1ZMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/investments-offer/[slug].vue?macro=true";
import { default as indexJB1UBWTkccMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/investments-offer/index.vue?macro=true";
import { default as investments_45offernzrVJLpAN7Meta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/investments-offer.vue?macro=true";
import { default as my_45investmentsvO7bMWpK3zMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/my-investments.vue?macro=true";
import { default as votingYEq92xr0S7Meta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/voting.vue?macro=true";
import { default as investmentsxmZWg30k1TMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/investments.vue?macro=true";
import { default as add_45accountgxsTjKfB4WMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/add-account.vue?macro=true";
import { default as additional_45settingsJBvLzySclrMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/additional-settings.vue?macro=true";
import { default as indexKRR7X4bV3JMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/bank-accounts/add/index.vue?macro=true";
import { default as requestediIOoX7i8MEMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/bank-accounts/add/requested.vue?macro=true";
import { default as upload_45documentE9C91xDRvqMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/bank-accounts/add/upload-document.vue?macro=true";
import { default as index1QGKEd9VVtMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/bank-accounts/index.vue?macro=true";
import { default as delete_45account11vEGqSX6LMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/delete-account.vue?macro=true";
import { default as indexFN3GW5ItInMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/document-update/index.vue?macro=true";
import { default as requestedPpP9O6No0gMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/document-update/requested.vue?macro=true";
import { default as edit_45personal_45informationqgaSVWDTIfMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/edit-personal-information.vue?macro=true";
import { default as email_45changeXRUznVWCtCMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/email-change.vue?macro=true";
import { default as email_45preferences1JN4R4rjcGMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/email-preferences.vue?macro=true";
import { default as czech_45pointGaajgDouX3Meta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/identity-verification/czech-point.vue?macro=true";
import { default as indexEU4LTrUxucMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/identity-verification/index.vue?macro=true";
import { default as indexvZvA5ihu7YMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/index.vue?macro=true";
import { default as mailing_45address_45changez4Lm0cbDjxMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/mailing-address-change.vue?macro=true";
import { default as indexMOZiZgP6RxMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/name-change/index.vue?macro=true";
import { default as requestedDA0DE8VybtMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/name-change/requested.vue?macro=true";
import { default as upload_45documentsy3DdXUSnb2Meta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/name-change/upload-documents.vue?macro=true";
import { default as indexKL61lucjuUMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/permanent-address-change/index.vue?macro=true";
import { default as requestedJPrDdtVIZoMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/permanent-address-change/requested.vue?macro=true";
import { default as upload_45documentsn0OSrTArtKMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/permanent-address-change/upload-documents.vue?macro=true";
import { default as additional_45informationjVxN75jg8gMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/additional-information.vue?macro=true";
import { default as indexoKyHPHwzYWMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/index.vue?macro=true";
import { default as indexiW8Ns7zu51Meta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/index.vue?macro=true";
import { default as requested4sKlqhXu3IMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/requested.vue?macro=true";
import { default as indexu8Y139kaihMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/index.vue?macro=true";
import { default as requestedmVzxuzDwsjMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/requested.vue?macro=true";
import { default as indexx973YyhsLoMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/index.vue?macro=true";
import { default as requestedbXVIf50EZsMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/requested.vue?macro=true";
import { default as personal_45data_45confirmation9AQ41HK5iUMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation.vue?macro=true";
import { default as phone_45changeWb1BbkfuiyMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/phone-change.vue?macro=true";
import { default as indexqMHbq9xDMFMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/security/index.vue?macro=true";
import { default as password_45changeZwVeo02ufdMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/security/password-change.vue?macro=true";
import { default as documentsjPV929IsmgMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/statements-and-documents/documents.vue?macro=true";
import { default as indexcCQocqCve5Meta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/statements-and-documents/index.vue?macro=true";
import { default as statements_45and_45confirmationsTvenOv7h8EMeta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/statements-and-documents/statements-and-confirmations.vue?macro=true";
import { default as profileyp6QI47pP2Meta } from "C:/FE02/_work/213/s/Gallery/pages/dashboard/profile.vue?macro=true";
import { default as elementSWyRV1m79QMeta } from "C:/FE02/_work/213/s/Gallery/pages/element.vue?macro=true";
import { default as email_45verificationNnVurn74FxMeta } from "C:/FE02/_work/213/s/Gallery/pages/external/email-verification.vue?macro=true";
import { default as impersonationKrNFrFYIIbMeta } from "C:/FE02/_work/213/s/Gallery/pages/external/impersonation.vue?macro=true";
import { default as loginDNrOp9SXHdMeta } from "C:/FE02/_work/213/s/Gallery/pages/external/login.vue?macro=true";
import { default as password_45resetLna8LRrVfXMeta } from "C:/FE02/_work/213/s/Gallery/pages/external/password-reset.vue?macro=true";
import { default as _91slug_93zjm3RyOtgeMeta } from "C:/FE02/_work/213/s/Gallery/pages/faq/[slug].vue?macro=true";
import { default as indext2P4AHWqUEMeta } from "C:/FE02/_work/213/s/Gallery/pages/faq/index.vue?macro=true";
import { default as faqAgW5HSy6QwMeta } from "C:/FE02/_work/213/s/Gallery/pages/faq.vue?macro=true";
import { default as forgotten_45passwordbKySa51BDYMeta } from "C:/FE02/_work/213/s/Gallery/pages/forgotten-password.vue?macro=true";
import { default as how_45does_45it_45workTyPCBq1glmMeta } from "C:/FE02/_work/213/s/Gallery/pages/how-does-it-work.vue?macro=true";
import { default as indexzc3nTChAJjMeta } from "C:/FE02/_work/213/s/Gallery/pages/index.vue?macro=true";
import { default as _91slug_93GQWSP4m3VsMeta } from "C:/FE02/_work/213/s/Gallery/pages/investments-offer/[slug].vue?macro=true";
import { default as indexb0ln6UCeO5Meta } from "C:/FE02/_work/213/s/Gallery/pages/investments-offer/index.vue?macro=true";
import { default as loginTdz9zJQ5klMeta } from "C:/FE02/_work/213/s/Gallery/pages/login.vue?macro=true";
import { default as obligatory_45published_45information9EgnI98msAMeta } from "C:/FE02/_work/213/s/Gallery/pages/obligatory-published-information.vue?macro=true";
import { default as privacy_45settingsSrISg5LX5LMeta } from "C:/FE02/_work/213/s/Gallery/pages/privacy-settings.vue?macro=true";
import { default as registrationZwwa7MW1RnMeta } from "C:/FE02/_work/213/s/Gallery/pages/registration.vue?macro=true";
import { default as securityT15cmkCbJJMeta } from "C:/FE02/_work/213/s/Gallery/pages/security.vue?macro=true";
export default [
  {
    name: "notfound___cs___default",
    path: "/:notfound(.*)*",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/[...not-found].vue").then(m => m.default || m)
  },
  {
    name: "notfound___cs",
    path: "/cs/:notfound(.*)*",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/[...not-found].vue").then(m => m.default || m)
  },
  {
    name: "about___cs___default",
    path: "/o-nas",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___cs",
    path: "/cs/o-nas",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "account-account-created___cs___default",
    path: "/ucet/ucet-vytvoren",
    meta: account_45created89byGPMxieMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/account-created.vue").then(m => m.default || m)
  },
  {
    name: "account-account-created___cs",
    path: "/cs/ucet/ucet-vytvoren",
    meta: account_45created89byGPMxieMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/account-created.vue").then(m => m.default || m)
  },
  {
    name: "account-additional-information___cs___default",
    path: "/ucet/dalsi-informace",
    meta: additional_45informationwshpded0ATMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "account-additional-information___cs",
    path: "/cs/ucet/dalsi-informace",
    meta: additional_45informationwshpded0ATMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___cs___default",
    path: "/ucet/adresy",
    meta: addresses4UR44RrFuCMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___cs",
    path: "/cs/ucet/adresy",
    meta: addresses4UR44RrFuCMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "account-amendment___cs___default",
    path: "/ucet/dodatek",
    meta: amendmentgsL3mWUY30Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/amendment.vue").then(m => m.default || m)
  },
  {
    name: "account-amendment___cs",
    path: "/cs/ucet/dodatek",
    meta: amendmentgsL3mWUY30Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/amendment.vue").then(m => m.default || m)
  },
  {
    name: "account-bank-account___cs___default",
    path: "/ucet/bankovni-ucet",
    meta: indexju3Via4vkCMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/bank-account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-bank-account___cs",
    path: "/cs/ucet/bankovni-ucet",
    meta: indexju3Via4vkCMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/bank-account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-bank-account-requested___cs___default",
    path: "/ucet/bankovni-ucet/overeni",
    meta: requestedQyiHbQ05BmMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/bank-account/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-bank-account-requested___cs",
    path: "/cs/ucet/bankovni-ucet/overeni",
    meta: requestedQyiHbQ05BmMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/bank-account/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-contract-signing___cs___default",
    path: "/ucet/podpis-smlouvy",
    meta: contract_45signingGK8eCMMMizMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/contract-signing.vue").then(m => m.default || m)
  },
  {
    name: "account-contract-signing___cs",
    path: "/cs/ucet/podpis-smlouvy",
    meta: contract_45signingGK8eCMMMizMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/contract-signing.vue").then(m => m.default || m)
  },
  {
    name: "account-document-update___cs___default",
    path: "/ucet/aktualizace-dokladu",
    meta: document_45update5U0vGNpM7MMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/document-update.vue").then(m => m.default || m)
  },
  {
    name: "account-document-update___cs",
    path: "/cs/ucet/aktualizace-dokladu",
    meta: document_45update5U0vGNpM7MMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/document-update.vue").then(m => m.default || m)
  },
  {
    name: "account-documents-check___cs___default",
    path: "/ucet/kontrola-dokladu",
    meta: documents_45checkw1YBocu0flMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/documents-check.vue").then(m => m.default || m)
  },
  {
    name: "account-documents-check___cs",
    path: "/cs/ucet/kontrola-dokladu",
    meta: documents_45checkw1YBocu0flMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/documents-check.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification___cs___default",
    path: "/ucet/overeni-identity",
    meta: indexfnfjgs0N3UMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/identity-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification___cs",
    path: "/cs/ucet/overeni-identity",
    meta: indexfnfjgs0N3UMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/identity-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification-requested___cs___default",
    path: "/ucet/overeni-identity/overeni",
    meta: requestedawGuRAaZGrMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/identity-verification/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification-requested___cs",
    path: "/cs/ucet/overeni-identity/overeni",
    meta: requestedawGuRAaZGrMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/identity-verification/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification-upload-documents___cs___default",
    path: "/ucet/overeni-identity/nahrat-doklady",
    meta: upload_45documents3KKtg4nRXkMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/identity-verification/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification-upload-documents___cs",
    path: "/cs/ucet/overeni-identity/nahrat-doklady",
    meta: upload_45documents3KKtg4nRXkMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/identity-verification/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: "account-personal-data___cs___default",
    path: "/ucet/osobni-udaje",
    meta: personal_45datasXInFy6uXxMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/personal-data.vue").then(m => m.default || m)
  },
  {
    name: "account-personal-data___cs",
    path: "/cs/ucet/osobni-udaje",
    meta: personal_45datasXInFy6uXxMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/personal-data.vue").then(m => m.default || m)
  },
  {
    name: "account-phone-number-verification___cs___default",
    path: "/ucet/overeni-telefonniho-cisla",
    meta: phone_45number_45verificationOlnltbJDFOMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/phone-number-verification.vue").then(m => m.default || m)
  },
  {
    name: "account-phone-number-verification___cs",
    path: "/cs/ucet/overeni-telefonniho-cisla",
    meta: phone_45number_45verificationOlnltbJDFOMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/phone-number-verification.vue").then(m => m.default || m)
  },
  {
    name: "account-politically-exposed-person___cs___default",
    path: "/ucet/politicky-exponovana-osoba",
    meta: index8T0gCK7A3oMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/politically-exposed-person/index.vue").then(m => m.default || m)
  },
  {
    name: "account-politically-exposed-person___cs",
    path: "/cs/ucet/politicky-exponovana-osoba",
    meta: index8T0gCK7A3oMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/politically-exposed-person/index.vue").then(m => m.default || m)
  },
  {
    name: "account-politically-exposed-person-requested___cs___default",
    path: "/ucet/politicky-exponovana-osoba/overeni",
    meta: requestedsJES8p2x1HMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/politically-exposed-person/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-politically-exposed-person-requested___cs",
    path: "/cs/ucet/politicky-exponovana-osoba/overeni",
    meta: requestedsJES8p2x1HMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/politically-exposed-person/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-questionnaire___cs___default",
    path: "/ucet/dotaznik",
    meta: questionnaireTIUkWP34K1Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/questionnaire.vue").then(m => m.default || m)
  },
  {
    name: "account-questionnaire___cs",
    path: "/cs/ucet/dotaznik",
    meta: questionnaireTIUkWP34K1Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/questionnaire.vue").then(m => m.default || m)
  },
  {
    name: "account-source-of-income-statement___cs___default",
    path: "/ucet/potvrzeni-zdroje-prijmu",
    meta: indexfrpQSq57n9Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/source-of-income-statement/index.vue").then(m => m.default || m)
  },
  {
    name: "account-source-of-income-statement___cs",
    path: "/cs/ucet/potvrzeni-zdroje-prijmu",
    meta: indexfrpQSq57n9Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/source-of-income-statement/index.vue").then(m => m.default || m)
  },
  {
    name: "account-source-of-income-statement-requested___cs___default",
    path: "/ucet/potvrzeni-zdroje-prijmu/overeni",
    meta: requestedRZV0NSQN8zMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/source-of-income-statement/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-source-of-income-statement-requested___cs",
    path: "/cs/ucet/potvrzeni-zdroje-prijmu/overeni",
    meta: requestedRZV0NSQN8zMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/source-of-income-statement/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-verification___cs___default",
    path: "/ucet/verifikace",
    meta: verificationzUDhQqBJhVMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/verification.vue").then(m => m.default || m)
  },
  {
    name: "account-verification___cs",
    path: "/cs/ucet/verifikace",
    meta: verificationzUDhQqBJhVMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/account/verification.vue").then(m => m.default || m)
  },
  {
    name: "annual-reports___cs___default",
    path: "/vyrocni-zpravy",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/annual-reports.vue").then(m => m.default || m)
  },
  {
    name: "annual-reports___cs",
    path: "/cs/vyrocni-zpravy",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/annual-reports.vue").then(m => m.default || m)
  },
  {
    name: "bankid___cs___default",
    path: "/bankid",
    meta: bankidl5YX5Dlu0TMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/bankid.vue").then(m => m.default || m)
  },
  {
    name: "bankid___cs",
    path: "/cs/bankid",
    meta: bankidl5YX5Dlu0TMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/bankid.vue").then(m => m.default || m)
  },
  {
    name: "contact___cs___default",
    path: "/kontakt",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___cs",
    path: "/cs/kontakt",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contractual-documentation___cs___default",
    path: "/smluvni-dokumentace",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/contractual-documentation.vue").then(m => m.default || m)
  },
  {
    name: "contractual-documentation___cs",
    path: "/cs/smluvni-dokumentace",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/contractual-documentation.vue").then(m => m.default || m)
  },
  {
    name: advertising_45spacemVjTtWOJN0Meta?.name,
    path: "/souhrn/inzertni-plocha",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-advertising-space-id___cs___default",
    path: ":id()",
    meta: _91id_93yOgJekiScZMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-advertisement-id___cs___default",
    path: "inzerat/:id()",
    meta: _91id_93PyTYowHiRbMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/advertisement/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-advertisement-new___cs___default",
    path: "inzerat/novy",
    meta: newldlaJ2AlsiMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/advertisement/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space___cs___default",
    path: "/souhrn/inzertni-plocha",
    meta: indexBbl9ITyHtvMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-my-advertisements___cs___default",
    path: "moje-inzeraty",
    meta: my_45advertisementsmwddt4FSxpMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/my-advertisements.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-terms___cs___default",
    path: "podminky",
    meta: termsPxkUxQOYXrMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/terms.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades-id___cs___default",
    path: "pokyny-k-prevodu/:id()",
    meta: _91id_93NVgftnlcMhMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/trades/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades___cs___default",
    path: "pokyny-k-prevodu",
    meta: indexQGd5psaGXTMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/trades/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades-new___cs___default",
    path: "pokyny-k-prevodu/novy",
    meta: newxDrrkmeOrbMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/trades/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: advertising_45spacemVjTtWOJN0Meta?.name,
    path: "/cs/souhrn/inzertni-plocha",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-advertising-space-id___cs",
    path: ":id()",
    meta: _91id_93yOgJekiScZMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-advertisement-id___cs",
    path: "inzerat/:id()",
    meta: _91id_93PyTYowHiRbMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/advertisement/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-advertisement-new___cs",
    path: "inzerat/novy",
    meta: newldlaJ2AlsiMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/advertisement/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space___cs",
    path: "/cs/souhrn/inzertni-plocha",
    meta: indexBbl9ITyHtvMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-my-advertisements___cs",
    path: "moje-inzeraty",
    meta: my_45advertisementsmwddt4FSxpMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/my-advertisements.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-terms___cs",
    path: "podminky",
    meta: termsPxkUxQOYXrMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/terms.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades-id___cs",
    path: "pokyny-k-prevodu/:id()",
    meta: _91id_93NVgftnlcMhMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/trades/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades___cs",
    path: "pokyny-k-prevodu",
    meta: indexQGd5psaGXTMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/trades/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades-new___cs",
    path: "pokyny-k-prevodu/novy",
    meta: newxDrrkmeOrbMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/advertising-space/trades/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: financeE4CnVlxqpSMeta?.name,
    path: "/souhrn/penize",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/finance.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-finance-deposit___cs___default",
    path: "vklad",
    meta: depositubNLWpXU31Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/deposit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance___cs___default",
    path: "/souhrn/penize",
    meta: indexXEk0Kb2YL2Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-purchase-instructions___cs___default",
    path: "pokyny",
    meta: purchase_45instructions89hNf5NpeqMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/purchase-instructions.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-transactions-history___cs___default",
    path: "transakcni-historie",
    meta: transactions_45historyZfoFl45115Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/transactions-history.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-withdrawal-confirmation___cs___default",
    path: "vyber/potvrzeni",
    meta: confirmationfAJP39ZYhIMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/withdrawal/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-withdrawal___cs___default",
    path: "vyber",
    meta: indexqnDQl4F67pMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/withdrawal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: financeE4CnVlxqpSMeta?.name,
    path: "/cs/souhrn/penize",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/finance.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-finance-deposit___cs",
    path: "vklad",
    meta: depositubNLWpXU31Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/deposit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance___cs",
    path: "/cs/souhrn/penize",
    meta: indexXEk0Kb2YL2Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-purchase-instructions___cs",
    path: "pokyny",
    meta: purchase_45instructions89hNf5NpeqMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/purchase-instructions.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-transactions-history___cs",
    path: "transakcni-historie",
    meta: transactions_45historyZfoFl45115Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/transactions-history.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-withdrawal-confirmation___cs",
    path: "vyber/potvrzeni",
    meta: confirmationfAJP39ZYhIMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/withdrawal/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-withdrawal___cs",
    path: "vyber",
    meta: indexqnDQl4F67pMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/finance/withdrawal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: gifts_45and_45benefitsfgA9QgwpFcMeta?.name,
    path: "/souhrn/darky-a-vyhody",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/gifts-and-benefits.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-gifts-and-benefits___cs___default",
    path: "",
    meta: indexuB0mVHctNbMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/gifts-and-benefits/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gifts-and-benefits-unique-exhibition-alfons-mucha___cs___default",
    path: "unikatni-vystava-alfons-mucha",
    meta: unique_45exhibition_45alfons_45muchaHZr1AKrVCRMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/gifts-and-benefits/unique-exhibition-alfons-mucha.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gifts-and-benefits-vouchers___cs___default",
    path: "darkove-poukazy",
    meta: vouchersgmiivzAEfUMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/gifts-and-benefits/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: gifts_45and_45benefitsfgA9QgwpFcMeta?.name,
    path: "/cs/souhrn/darky-a-vyhody",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/gifts-and-benefits.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-gifts-and-benefits___cs",
    path: "",
    meta: indexuB0mVHctNbMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/gifts-and-benefits/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gifts-and-benefits-unique-exhibition-alfons-mucha___cs",
    path: "unikatni-vystava-alfons-mucha",
    meta: unique_45exhibition_45alfons_45muchaHZr1AKrVCRMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/gifts-and-benefits/unique-exhibition-alfons-mucha.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gifts-and-benefits-vouchers___cs",
    path: "darkove-poukazy",
    meta: vouchersgmiivzAEfUMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/gifts-and-benefits/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard___cs___default",
    path: "/souhrn",
    meta: indexyeY3BsVCFMMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___cs",
    path: "/cs/souhrn",
    meta: indexyeY3BsVCFMMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: investmentsxmZWg30k1TMeta?.name,
    path: "/souhrn/investice",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/investments.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-investments___cs___default",
    path: "",
    meta: index1vDmhh3GFvMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/index.vue").then(m => m.default || m)
  },
  {
    name: investments_45offernzrVJLpAN7Meta?.name,
    path: "nabidka-investic",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/investments-offer.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-investments-investments-offer-slug___cs___default",
    path: "/souhrn/investice/:slug()",
    meta: _91slug_93GZ0FWtAS1ZMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/investments-offer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-investments-investments-offer___cs___default",
    path: "/souhrn/investice/nabidka-investic",
    meta: indexJB1UBWTkccMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/investments-offer/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-investments-my-investments___cs___default",
    path: "moje-investice",
    meta: my_45investmentsvO7bMWpK3zMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/my-investments.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-investments-voting___cs___default",
    path: "hlasovani",
    meta: votingYEq92xr0S7Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/voting.vue").then(m => m.default || m)
  }
]
  },
  {
    name: investmentsxmZWg30k1TMeta?.name,
    path: "/cs/souhrn/investice",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/investments.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-investments___cs",
    path: "",
    meta: index1vDmhh3GFvMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/index.vue").then(m => m.default || m)
  },
  {
    name: investments_45offernzrVJLpAN7Meta?.name,
    path: "nabidka-investic",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/investments-offer.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-investments-investments-offer-slug___cs",
    path: "/cs/souhrn/investice/:slug()",
    meta: _91slug_93GZ0FWtAS1ZMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/investments-offer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-investments-investments-offer___cs",
    path: "/cs/souhrn/investice/nabidka-investic",
    meta: indexJB1UBWTkccMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/investments-offer/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-investments-my-investments___cs",
    path: "moje-investice",
    meta: my_45investmentsvO7bMWpK3zMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/my-investments.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-investments-voting___cs",
    path: "hlasovani",
    meta: votingYEq92xr0S7Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/investments/voting.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileyp6QI47pP2Meta?.name,
    path: "/dashboard/profile",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-add-account___cs___default",
    path: "/souhrn/profil/pridat-ucet",
    meta: add_45accountgxsTjKfB4WMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/add-account.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-additional-settings___cs___default",
    path: "/souhrn/profil/dalsi-nastaveni",
    meta: additional_45settingsJBvLzySclrMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/additional-settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add___cs___default",
    path: "/souhrn/profil/bankovni-ucty/pridat",
    meta: indexKRR7X4bV3JMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/bank-accounts/add/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add-requested___cs___default",
    path: "/souhrn/profil/bankovni-ucty/pridat/overeni",
    meta: requestediIOoX7i8MEMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/bank-accounts/add/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add-upload-document___cs___default",
    path: "/souhrn/profil/bankovni-ucty/pridat/nahrat-doklad",
    meta: upload_45documentE9C91xDRvqMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/bank-accounts/add/upload-document.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts___cs___default",
    path: "/souhrn/profil/bankovni-ucty",
    meta: index1QGKEd9VVtMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/bank-accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-delete-account___cs___default",
    path: "/souhrn/profil/zruseni-uctu",
    meta: delete_45account11vEGqSX6LMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/delete-account.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-document-update___cs___default",
    path: "/souhrn/profil/aktualizace-dokladu",
    meta: indexFN3GW5ItInMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/document-update/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-document-update-requested___cs___default",
    path: "/souhrn/profil/aktualizace-dokladu/overeni",
    meta: requestedPpP9O6No0gMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/document-update/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-edit-personal-information___cs___default",
    path: "/souhrn/profil/uprava-osobnich-udaju",
    meta: edit_45personal_45informationqgaSVWDTIfMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/edit-personal-information.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-email-change___cs___default",
    path: "/souhrn/profil/zmena-e-mailu",
    meta: email_45changeXRUznVWCtCMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/email-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-email-preferences___cs___default",
    path: "/souhrn/profil/e-mailova-sdeleni",
    meta: email_45preferences1JN4R4rjcGMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/email-preferences.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-identity-verification-czech-point___cs___default",
    path: "/souhrn/profil/overeni-totoznosti/czech-point",
    meta: czech_45pointGaajgDouX3Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/identity-verification/czech-point.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-identity-verification___cs___default",
    path: "/souhrn/profil/overeni-totoznosti",
    meta: indexEU4LTrUxucMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/identity-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile___cs___default",
    path: "/souhrn/profil",
    meta: indexvZvA5ihu7YMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-mailing-address-change___cs___default",
    path: "/souhrn/profil/zmena-korespondencni-adresy",
    meta: mailing_45address_45changez4Lm0cbDjxMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/mailing-address-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change___cs___default",
    path: "/souhrn/profil/zmena-jmena",
    meta: indexMOZiZgP6RxMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/name-change/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change-requested___cs___default",
    path: "/souhrn/profil/zmena-jmena/overeni",
    meta: requestedDA0DE8VybtMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/name-change/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change-upload-documents___cs___default",
    path: "/souhrn/profil/zmena-jmena/nahrat-doklady",
    meta: upload_45documentsy3DdXUSnb2Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/name-change/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change___cs___default",
    path: "/souhrn/profil/zmena-travale-adresy",
    meta: indexKL61lucjuUMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/permanent-address-change/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change-requested___cs___default",
    path: "/souhrn/profil/zmena-travale-adresy/overeni",
    meta: requestedJPrDdtVIZoMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/permanent-address-change/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change-upload-documents___cs___default",
    path: "/souhrn/profil/zmena-travale-adresy/nahrat-doklady",
    meta: upload_45documentsn0OSrTArtKMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/permanent-address-change/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: personal_45data_45confirmation9AQ41HK5iUMeta?.name,
    path: "personal-data-confirmation",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-personal-data-confirmation-additional-information___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/dalsi-informace",
    meta: additional_45informationjVxN75jg8gMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation___cs___default",
    path: "/souhrn/profil/aktualizace-udaju",
    meta: indexoKyHPHwzYWMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-new-document___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/novy-dokument",
    meta: indexiW8Ns7zu51Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-new-document-requested___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/novy-dokument/overeni",
    meta: requested4sKlqhXu3IMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-politically-exposed-person___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba",
    meta: indexu8Y139kaihMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-politically-exposed-person-requested___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba/overeni",
    meta: requestedmVzxuzDwsjMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-source-of-income-statement___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu",
    meta: indexx973YyhsLoMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-source-of-income-statement-requested___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu/overeni",
    meta: requestedbXVIf50EZsMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/requested.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-profile-phone-change___cs___default",
    path: "/souhrn/profil/zmena-telefonu",
    meta: phone_45changeWb1BbkfuiyMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/phone-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-security___cs___default",
    path: "/souhrn/profil/bezpecnost",
    meta: indexqMHbq9xDMFMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/security/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-security-password-change___cs___default",
    path: "/souhrn/profil/bezpecnost/zmena-hesla",
    meta: password_45changeZwVeo02ufdMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/security/password-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents-documents___cs___default",
    path: "/souhrn/profil/vypisy-a-dokumenty/dokumenty",
    meta: documentsjPV929IsmgMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/statements-and-documents/documents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents___cs___default",
    path: "/souhrn/profil/vypisy-a-dokumenty",
    meta: indexcCQocqCve5Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/statements-and-documents/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents-statements-and-confirmations___cs___default",
    path: "/souhrn/profil/vypisy-a-dokumenty/vypisy-a-potvrzeni",
    meta: statements_45and_45confirmationsTvenOv7h8EMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/statements-and-documents/statements-and-confirmations.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileyp6QI47pP2Meta?.name,
    path: "/cs/dashboard/profile",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-add-account___cs",
    path: "/cs/souhrn/profil/pridat-ucet",
    meta: add_45accountgxsTjKfB4WMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/add-account.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-additional-settings___cs",
    path: "/cs/souhrn/profil/dalsi-nastaveni",
    meta: additional_45settingsJBvLzySclrMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/additional-settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add___cs",
    path: "/cs/souhrn/profil/bankovni-ucty/pridat",
    meta: indexKRR7X4bV3JMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/bank-accounts/add/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add-requested___cs",
    path: "/cs/souhrn/profil/bankovni-ucty/pridat/overeni",
    meta: requestediIOoX7i8MEMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/bank-accounts/add/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add-upload-document___cs",
    path: "/cs/souhrn/profil/bankovni-ucty/pridat/nahrat-doklad",
    meta: upload_45documentE9C91xDRvqMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/bank-accounts/add/upload-document.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts___cs",
    path: "/cs/souhrn/profil/bankovni-ucty",
    meta: index1QGKEd9VVtMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/bank-accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-delete-account___cs",
    path: "/cs/souhrn/profil/zruseni-uctu",
    meta: delete_45account11vEGqSX6LMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/delete-account.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-document-update___cs",
    path: "/cs/souhrn/profil/aktualizace-dokladu",
    meta: indexFN3GW5ItInMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/document-update/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-document-update-requested___cs",
    path: "/cs/souhrn/profil/aktualizace-dokladu/overeni",
    meta: requestedPpP9O6No0gMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/document-update/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-edit-personal-information___cs",
    path: "/cs/souhrn/profil/uprava-osobnich-udaju",
    meta: edit_45personal_45informationqgaSVWDTIfMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/edit-personal-information.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-email-change___cs",
    path: "/cs/souhrn/profil/zmena-e-mailu",
    meta: email_45changeXRUznVWCtCMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/email-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-email-preferences___cs",
    path: "/cs/souhrn/profil/e-mailova-sdeleni",
    meta: email_45preferences1JN4R4rjcGMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/email-preferences.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-identity-verification-czech-point___cs",
    path: "/cs/souhrn/profil/overeni-totoznosti/czech-point",
    meta: czech_45pointGaajgDouX3Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/identity-verification/czech-point.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-identity-verification___cs",
    path: "/cs/souhrn/profil/overeni-totoznosti",
    meta: indexEU4LTrUxucMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/identity-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile___cs",
    path: "/cs/souhrn/profil",
    meta: indexvZvA5ihu7YMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-mailing-address-change___cs",
    path: "/cs/souhrn/profil/zmena-korespondencni-adresy",
    meta: mailing_45address_45changez4Lm0cbDjxMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/mailing-address-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change___cs",
    path: "/cs/souhrn/profil/zmena-jmena",
    meta: indexMOZiZgP6RxMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/name-change/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change-requested___cs",
    path: "/cs/souhrn/profil/zmena-jmena/overeni",
    meta: requestedDA0DE8VybtMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/name-change/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change-upload-documents___cs",
    path: "/cs/souhrn/profil/zmena-jmena/nahrat-doklady",
    meta: upload_45documentsy3DdXUSnb2Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/name-change/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change___cs",
    path: "/cs/souhrn/profil/zmena-travale-adresy",
    meta: indexKL61lucjuUMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/permanent-address-change/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change-requested___cs",
    path: "/cs/souhrn/profil/zmena-travale-adresy/overeni",
    meta: requestedJPrDdtVIZoMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/permanent-address-change/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change-upload-documents___cs",
    path: "/cs/souhrn/profil/zmena-travale-adresy/nahrat-doklady",
    meta: upload_45documentsn0OSrTArtKMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/permanent-address-change/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: personal_45data_45confirmation9AQ41HK5iUMeta?.name,
    path: "personal-data-confirmation",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-personal-data-confirmation-additional-information___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/dalsi-informace",
    meta: additional_45informationjVxN75jg8gMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju",
    meta: indexoKyHPHwzYWMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-new-document___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/novy-dokument",
    meta: indexiW8Ns7zu51Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-new-document-requested___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/novy-dokument/overeni",
    meta: requested4sKlqhXu3IMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-politically-exposed-person___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba",
    meta: indexu8Y139kaihMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-politically-exposed-person-requested___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba/overeni",
    meta: requestedmVzxuzDwsjMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-source-of-income-statement___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu",
    meta: indexx973YyhsLoMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-source-of-income-statement-requested___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu/overeni",
    meta: requestedbXVIf50EZsMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/requested.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-profile-phone-change___cs",
    path: "/cs/souhrn/profil/zmena-telefonu",
    meta: phone_45changeWb1BbkfuiyMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/phone-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-security___cs",
    path: "/cs/souhrn/profil/bezpecnost",
    meta: indexqMHbq9xDMFMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/security/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-security-password-change___cs",
    path: "/cs/souhrn/profil/bezpecnost/zmena-hesla",
    meta: password_45changeZwVeo02ufdMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/security/password-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents-documents___cs",
    path: "/cs/souhrn/profil/vypisy-a-dokumenty/dokumenty",
    meta: documentsjPV929IsmgMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/statements-and-documents/documents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents___cs",
    path: "/cs/souhrn/profil/vypisy-a-dokumenty",
    meta: indexcCQocqCve5Meta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/statements-and-documents/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents-statements-and-confirmations___cs",
    path: "/cs/souhrn/profil/vypisy-a-dokumenty/vypisy-a-potvrzeni",
    meta: statements_45and_45confirmationsTvenOv7h8EMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/dashboard/profile/statements-and-documents/statements-and-confirmations.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "element___cs___default",
    path: "/element",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/element.vue").then(m => m.default || m)
  },
  {
    name: "element___cs",
    path: "/cs/element",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/element.vue").then(m => m.default || m)
  },
  {
    name: "external-email-verification___cs___default",
    path: "/external/email-verification",
    meta: email_45verificationNnVurn74FxMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/external/email-verification.vue").then(m => m.default || m)
  },
  {
    name: "external-email-verification___cs",
    path: "/cs/external/email-verification",
    meta: email_45verificationNnVurn74FxMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/external/email-verification.vue").then(m => m.default || m)
  },
  {
    name: "external-impersonation___cs___default",
    path: "/external/impersonation",
    meta: impersonationKrNFrFYIIbMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/external/impersonation.vue").then(m => m.default || m)
  },
  {
    name: "external-impersonation___cs",
    path: "/cs/external/impersonation",
    meta: impersonationKrNFrFYIIbMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/external/impersonation.vue").then(m => m.default || m)
  },
  {
    name: "external-login___cs___default",
    path: "/external/login",
    meta: loginDNrOp9SXHdMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/external/login.vue").then(m => m.default || m)
  },
  {
    name: "external-login___cs",
    path: "/cs/external/login",
    meta: loginDNrOp9SXHdMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/external/login.vue").then(m => m.default || m)
  },
  {
    name: "external-password-reset___cs___default",
    path: "/external/password-reset",
    meta: password_45resetLna8LRrVfXMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/external/password-reset.vue").then(m => m.default || m)
  },
  {
    name: "external-password-reset___cs",
    path: "/cs/external/password-reset",
    meta: password_45resetLna8LRrVfXMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/external/password-reset.vue").then(m => m.default || m)
  },
  {
    name: faqAgW5HSy6QwMeta?.name,
    path: "/faq",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/faq.vue").then(m => m.default || m),
    children: [
  {
    name: "faq-slug___cs___default",
    path: ":slug()",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faq___cs___default",
    path: "",
    meta: indext2P4AHWqUEMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/faq/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: faqAgW5HSy6QwMeta?.name,
    path: "/cs/faq",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/faq.vue").then(m => m.default || m),
    children: [
  {
    name: "faq-slug___cs",
    path: ":slug()",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faq___cs",
    path: "",
    meta: indext2P4AHWqUEMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/faq/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forgotten-password___cs___default",
    path: "/zapomenute-heslo",
    meta: forgotten_45passwordbKySa51BDYMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/forgotten-password.vue").then(m => m.default || m)
  },
  {
    name: "forgotten-password___cs",
    path: "/cs/zapomenute-heslo",
    meta: forgotten_45passwordbKySa51BDYMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/forgotten-password.vue").then(m => m.default || m)
  },
  {
    name: "how-does-it-work___cs___default",
    path: "/jak-to-funguje",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/how-does-it-work.vue").then(m => m.default || m)
  },
  {
    name: "how-does-it-work___cs",
    path: "/cs/jak-to-funguje",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/how-does-it-work.vue").then(m => m.default || m)
  },
  {
    name: "index___cs___default",
    path: "/",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___cs",
    path: "/cs",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "investments-offer-slug___cs___default",
    path: "/nabidka-investic/:slug()",
    meta: _91slug_93GQWSP4m3VsMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/investments-offer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "investments-offer-slug___cs",
    path: "/cs/nabidka-investic/:slug()",
    meta: _91slug_93GQWSP4m3VsMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/investments-offer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "investments-offer___cs___default",
    path: "/nabidka-investic",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/investments-offer/index.vue").then(m => m.default || m)
  },
  {
    name: "investments-offer___cs",
    path: "/cs/nabidka-investic",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/investments-offer/index.vue").then(m => m.default || m)
  },
  {
    name: "login___cs___default",
    path: "/prihlasit-se",
    meta: loginTdz9zJQ5klMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___cs",
    path: "/cs/prihlasit-se",
    meta: loginTdz9zJQ5klMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "obligatory-published-information___cs___default",
    path: "/povinne-informace",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/obligatory-published-information.vue").then(m => m.default || m)
  },
  {
    name: "obligatory-published-information___cs",
    path: "/cs/povinne-informace",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/obligatory-published-information.vue").then(m => m.default || m)
  },
  {
    name: "privacy-settings___cs___default",
    path: "/nastaveni-soukromi",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/privacy-settings.vue").then(m => m.default || m)
  },
  {
    name: "privacy-settings___cs",
    path: "/cs/nastaveni-soukromi",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/privacy-settings.vue").then(m => m.default || m)
  },
  {
    name: "registration___cs___default",
    path: "/registrace",
    meta: registrationZwwa7MW1RnMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "registration___cs",
    path: "/cs/registrace",
    meta: registrationZwwa7MW1RnMeta || {},
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "security___cs___default",
    path: "/bezpecnost",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/security.vue").then(m => m.default || m)
  },
  {
    name: "security___cs",
    path: "/cs/bezpecnost",
    component: () => import("C:/FE02/_work/213/s/Gallery/pages/security.vue").then(m => m.default || m)
  }
]